<template>
  <div style="background-color: #f5faff; z-index: 0; height: 100vh">
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      class="topNavBar"
    ></NavBar>


    <v-app>
      <!-- view prescription dialog start  -->
        <v-dialog v-model="dialog"  overlay-opacity="8" fullscreen>
          <v-card-actions>
            <v-card elevation="8">
              <v-card-title style="word-break: normal; justify-content: space-between">
                <div>Patient Prescription</div>
                <div>
                  <v-btn
                    icon
                    color="red"
                    class="text--white"
                    v-on:click="closeDialog"
                  >
                    <h2>X</h2>
                  </v-btn>
                </div>
              </v-card-title>
              <div style="padding:0px 20px;">
                <v-row>
                  <v-col cols="12" style="width: 100vw">
                    <div
                      style=" display: flex;
                        flex-direction: row;
                        margin-top: 10px;" >
                      <div>Booking ID:</div>
                      <div align="left" style="width: 50%">
                        <span style="padding-left: 1%; font-weight: 600">{{
                          appointment_reference_number
                        }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left" class="persistingSymptomsClass presentingSysmptoms">
                    Presenting Symptoms
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="persistingSymptomsClass presentingSysmptoms">
                    <p class="variable">{{presenting_symptoms}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Past medical history
                    <p class="variable ml-2">{{medical_history}}</p>
                  </v-col>
                  <v-col style="border-style: hidden groove hidden hidden" cols="4" lg="4" sm="4" md="4" xl="4"
                  class="docViewAppointmentMedicalDetails">
                    Past surgical history
                    <p class="variable ml-2">{{surgical_history}}</p>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Family history
                   <p class="variable ml-2">{{family_history}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Regular taking/ Current medicine
                   <p class="variable ml-2">{{current_taking_medicines}}</p>
                  </v-col>
                  <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Known Drug Allergies
                   <p class="variable ml-2">{{allergies}}</p>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Vaccination History
                    <p class="variable ml-2">{{vaccination_history}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    Diagnosis
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                   <p class="variable ml-2">{{diagnosed_with}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col cols="3" sm="3" md="3" align="left" class="docViewAppointmentMedicalDetails">
                    Treatment
                  </v-col>
                  <v-col cols="12" sm="12" md="12" align="left">
                    <table style=" width: 100%; background-color: #F1F6FF;" class="prescriptionTableClass">
                      <tr align="left" style="padding: 15px;">
                        <th style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</th>
                        <th style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</th>
                        <th style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</th>
                        <th style="font-size:16px; line-height: 20px; padding: 5px;">Duration</th>
                      </tr>
                      <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                         <td style="padding:5px;font-weight: 500">{{item.medicine}}</td>
                         <td style="padding:5px;font-weight: 500">{{item.dosage}}</td>
                         <td style="padding:5px;font-weight: 500">{{item.frequency}}</td>
                         <td style="padding:5px;font-weight: 500">{{item.duration}}</td>
                      </tr>
                    </table>
                    <table style=" width: 100%; background-color: #F1F6FF; margin-top:18px" class="PrescriptionTableMobileLayout" v-for="(item,index) in prescription_object" :key=index>
                      <tr align="left">
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Medicine</td>
                      </tr>
                      <tr>
                        <td style="padding:5px;"><v-text-field readonly class="textfield_class" solo outlined
                         v-model="item.medicine"/></td>
                      </tr>
                      <tr>
                      <table >
                      <tr>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Dosage</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Frequency</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Duration</td>
                      </tr>
                      <tr >
                        <td style="padding:5px;"><v-text-field readonly class="textfield_class" solo outlined
                         v-model="item.dosage"/></td>
                        <td style="padding:5px;"><v-text-field readonly class="textfield_class" solo outlined
                         v-model="item.frequency"/></td>
                        <td style="padding:5px;"><v-text-field readonly class="textfield_class" solo outlined
                         v-model="item.duration"/></td>
                      </tr>
                         </table>
                      </tr>
                      <tr>
                        <td v-if="prescriptionId.length < 0">
                          <!-- <img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                          <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"> -->
                          <v-btn  width="100%" color="primary" v-if="index == prescription_object.length-1" @click="add_rec" style="background-color: #1467BF;">
                            + Add More
                          </v-btn>
                          <v-btn width="100%" color="warning" v-else-if="index < prescription_object.length" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)" style="background-color: #EB5757;">
                            Delete Row
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <v-divider/>
                   <v-row  style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left"
                  class="docViewAppointmentMedicalDetails">
                    Suggestions
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                    <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                    <p class="variable">{{suggestions}}</p>
                  </v-col>
                </v-row>
                <v-row  style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    Investigations needed
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                    <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                    <p class="variable">{{investigations_needed}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    To Come after
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                    <!-- <v-select disabled background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                    <p class="variable">{{come_after}}</p>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row  style = "margin: 10px 0px;">
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    Refer To
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                    <!-- <v-select disabled background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/> -->
                    <p class="variable">{{selected_specialization.specialization_name}}</p>
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" v-if="selected_specialization.specialization_name == 'Others'">
                    <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="others" solo rows=1></v-textarea> -->
                    <p class="variable">{{others}}</p>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            </v-card-actions>
        </v-dialog>
      <!-- view prescription dialog ends  -->

      <div style="background-color: #f5faff; height: 93.5vh !important;">
         <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px;" :pageName="pageName" class="sideBar"></SideBar>
          </v-col>
          <v-col cols="11">
            <div style="position: fixed; left: 350px;top: 60px; width: 70vw;overflow: scroll; height: 45vw; margin-top: 2.5%;" align="left" class="viewPatientMainClass">
              <div class="pageTitleClass">
                <span
                  style="text-align: left;
                        margin-top: 80px;
                        margin-bottom: 20px;
                        font-weight: 800;
                        letter-spacing: 1px;
                        margin-bottom: 10px;
                        font-size: 12pt;
                        text-transform: uppercase;"
                >
                  Patient Details
                </span>
              </div>
              <v-card flat style="margin-bottom: 8px">
              <div
                class="header"
                style="display: flex;
                  flex-direction: row;
                  padding-top: 10px;
                  padding-left: 5px;">
                <div class="bookAppointmentMainDiv" style="display: flex; flex-direction: row; width: 100%">
                  <div
                      style="width: 10%; padding-top: 6px"
                      align="left"
                      v-on:click="redirectToAppointmentHistory()"
                    >
                    <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                  </div>
                  <div style="width: 90%" align="left">
                    <span style="font-weight: 600; font-size: 15pt">Patient Details</span>
                  </div>
                </div>
              </div>
            </v-card>
            <v-card width="98%" style="margin-right: 5%; border-radius: 12px; margin-top: 2%;" align="center" class="patientDetailsClass">
              <div style="display: flex; flex-direction: column" align="center">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 15px !important;
                  "
                >
                  <div>
                    <img
                      src="https://img.icons8.com/color/50/000000/user.png"
                    />
                  </div>
                  <div
                    style="display: flex; flex-direction: column; padding: 2px"
                  >
                    <div>
                      <h6
                        style="margin-top: 0px; color: #828282 !important"
                        align="left"
                      >
                        Patient Name
                      </h6>
                    </div>
                    <div v-if="this.patientDetails.customer_name ">
                      <p style="margin-top: 2px; font-weight: 700">
                        {{ this.patientDetails.customer_name }}
                      </p>
                    </div>
                     <div v-else>
                      <p style="margin-top: 2px; font-weight: 700" >
                        {{ this.patientDetails.dependent_name }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-top: 30px !important;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">Age:</p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px">
                      <strong>{{ age }}</strong>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/gender.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">Gender:</p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-if="patientDetails.customer_gender">
                      <strong>{{ patientDetails.customer_gender }}</strong>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-else>
                      <strong>{{ patientDetails.dependent_gender }}</strong>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/height.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">Height:</p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-if="patientDetails.customer_height">
                      <strong>{{ patientDetails.customer_height }}</strong>
                    </div>
                     <div style="margin-top: 3px; margin-left: 5px" v-else>
                      <strong>{{ patientDetails.dependent_height }}</strong>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/weight.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">Weight:</p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-if="patientDetails.customer_weight">
                      <strong>{{ patientDetails.customer_weight }}</strong>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-else>
                      <strong>{{ patientDetails.dependent_weight }}</strong>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">
                        Alcoholic:
                      </p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-if="patientDetails.alcoholic">
                      <strong>{{
                        patientDetails.alcoholic
                      }}</strong>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-else>
                      <strong>{{
                        patientDetails.dependent_alcoholic
                      }}</strong>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px">Smoker:</p>
                    </div>
                    <div style="margin-top: 3px; margin-left: 5px" v-if="patientDetails.smoker">
                      <strong>{{
                        patientDetails.smoker
                      }}</strong>
                    </div>
                     <div style="margin-top: 3px; margin-left: 5px" v-else  >
                      <strong>{{
                        patientDetails.dependent_smoker
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <br>
            <div class="mobileResponsivePatientDetails">
              <doctorViewPatinetCard :patientDetails ='patientDetails' ></doctorViewPatinetCard>
            </div>

            <div v-if="!isLoadingPrescription" >
              <v-card  width="98%" style="border-radius:8px; padding:2%;" class="mt-2" flat>
                  <span
                    style="
                      text-align: left;
                      margin-top: 50px !important;
                      font-weight: 800;
                      margin-bottom: 10px;
                      font-size: 12pt;
                      text-transform: uppercase;
                      margin-left:1%;
                    "
                  >
                    Prescription History
                  </span>
                  <div style="margin-right: 2%; border-radius: 12px;">
                    <div style="display: flex; flex-dirextion:row; flex-wrap:wrap;" class="prescriptionHistoryClass">
                        <v-card
                          style="border-radius: 12px; margin: 2%;box-shadow: 0px 8px 20px rgba(5, 71, 137, 0.1); width:300px"
                          class="prescriptionCard"
                          v-for="prescription in prescriptionDetails"
                          :key="prescription.appointment_reference_number"
                          align="center"
                        >
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              justify-content: left;
                              margin-top: 15px !important;">
                          <div>
                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-if="prescription.diagnosed_with != ''">
                              {{ prescription.diagnosed_with }}
                            </h4>
                            <h4
                              align="left"
                              style="padding-top: 10px; margin-left: 20px"
                              v-else>
                              NA
                            </h4>
                          </div>
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;">
                              <div
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  padding: 2px;
                                  margin-top: 5%;
                                  margin-left: 5%;">
                                <div>
                                  <h6
                                    style="
                                      margin-top: 20%;
                                      color: #828282 !important;"
                                      align="left">
                                    Doctor Name
                                  </h6>
                                </div>
                                <div>
                                  <p style="margin-top: 2px; font-weight: 700" align="left">
                                    {{ prescription.doctor_name }}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div
                                  style="
                                    display: flex;
                                    flex-direction: row;
                                    margin-top: 35%;
                                    margin-right: 10px;">
                                  <div>
                                    <img
                                      style="width: 25px; height: 25px"
                                      src="https://s3iconimages.mymedicine.com.mm/calendar.svg"/>
                                  </div>
                                  <div style="margin-top: 3px; margin-left: 5px">
                                    <strong>{{ prescription.date_of_appointment }}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="margin-top: 10px; margin-bottom: 10px;">
                              <v-btn
                                color="primary"
                                width="85%"
                                @click="viewPrescription(prescription._id)"
                                style="border-radius: 8px; bottom: 10px;background-color: #1467BF;">
                                View Prescription
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                    </div>
                  </div>
              </v-card>
            </div>
            <div v-else style="margin-top:20px" align="center">
                <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>

            </div>

          </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "DoctorViewPatient",
  components: {
    NavBar,
    SideBar,
    doctorViewPatinetCard : () => import('../../../components/doctorViewPatinetCard.vue')
  },
  data() {
    return {
      pageName: "DoctorViewPatient",
      dialog: false,
      appointment_reference_number: "",
      currentUser: "",
      currentUserName: "",
      doctor_id: "",
      patient_id: "",
      patientDetails: "",
      age: "",
      prescriptionDetails: "",
      isLoadingPrescription: true,
      prescriptionId: "",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      need_follow_up: false,
      exams: "",
      suggestions: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      current_taking_medicines: "",
      diagnosed_with: "",
      allergies: "",
      selected: [],
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      follow_up_list: ["1 Day", "2 Days", "4 Days", "7 Days","Not Required"],
      come_after: "",
      others: "",
      specialization_list: [],
      selected_specialization: ""
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("doctorToken");
    document.title = "View Patient Details"
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var params = this.$route.params;
      this.patient_id = params["patient_id"];
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        customerId : this.patient_id,
        page: 1,
        limit: 8,
      };
      axios_auth_instance_doctor.post("/doctorViewPatientsPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.doctor_id = successResponse.data.doctor_data._id;
        this.patientDetails = successResponse.data.patient_data;
        if(successResponse.data.dependent_flag){
          this.age = this.getAge(this.patientDetails.dependent_dob);
        }
        else{
          this.age = this.getAge(this.patientDetails.customer_dob);
        }
        this.prescriptionDetails = successResponse.data.prescription_data.docs;
        this.isLoadingPrescription = false;
      })
      .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'doctorViewPatientsPage', 'DoctorLogin', 'doctorToken');
      })
    }
  },
  methods: {
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    viewPrescription(prescriptionID) {
      this.dialog = true;
      this.prescriptionId = prescriptionID;
      var getPrescriptionData = {
        token: this.currentUser,
        typeOfUser: "DOCTOR",
        prescriptionId: this.prescriptionId,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/readParticularPrescription",
          getPrescriptionData
        )
        .then((readParticularPrescriptionResponse) => {
          this.particularPrescriptionDetails =
            readParticularPrescriptionResponse.data.data;
          this.appointment_reference_number =
            this.particularPrescriptionDetails.appointment_reference_number;
          var presc_dets = readParticularPrescriptionResponse.data.data;
          this.selected = presc_dets['covid_related_symptoms'];
          this.allergies = presc_dets['allergies'];
          this.diagnosed_with = presc_dets['diagnosed_with'];
          this.current_taking_medicines = presc_dets['current_taking_medicines'];
          this.suggestions = presc_dets['suggestion'];
          this.allergies = presc_dets['allergies'];
          if(presc_dets['need_follow_up'] == true)
            this.need_follow_up = "true"
          this.exams = presc_dets['examination_to_be_done'];
          if(presc_dets['patient_no_show'] == true)
            this.patient_no_show = presc_dets['patient_no_show'];
          if(presc_dets['referred_to_specialist'] == true)
            this.referred_to_specialist = "true";
          if(presc_dets['unstable_connectivity'] == true)
          {
            this.unstable_connectivity = "true";
          }
          if(presc_dets['prescription'].length > 0)
            this.prescription_object = presc_dets['prescription'];
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.suggestions = presc_dets.suggestion;
          this.come_after = presc_dets.next_followup_date;
          axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", getPrescriptionData)
          .then((readAllSpecializationsResponse) => {
            var spec_list = readAllSpecializationsResponse.data.data;
            this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
            spec_list.forEach((x) =>{
              this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
            });
            let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
            if(obj)
            {
              this.selected_specialization = obj;
            }
            else if(presc_dets)
            {
              this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
              this.others = presc_dets.referredSpecialistName;
            }
          }).catch((readAllSpecializationsError) =>{
          });
        })
        .catch((readParticularPrescriptionError) => {
          if (readParticularPrescriptionError.response.status == 401) {
            window.alert("Sorry !! You are UNAUTHORIZED");
            this.$router.push({
              name: "DoctorLogin",
            });
          }
        });
    },
    closeDialog() {
      this.dialog = false;
    },
    redirectToAppointmentHistory(){
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>
.v-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
}
.fieldset_class
{
  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-bottom-style: none;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
}
.variable {
  font-weight: 600;
}
.mobileResponsivePatientDetails{
  display: none !important;
}
.header{
  display: none !important;
}

.PrescriptionTableMobileLayout{
  display: none;
}
@media (min-width: 320px) and (max-width: 1024px){
  .topNavBar,.sideBar {
    display: none !important;
  }
  .pageTitleClass{
    display: none !important;
  }
  .header {
      display: block !important;
      box-shadow: 0px 2px 15px 0px #00000026;
      padding: 16px 20px !important;
    }
  .col-11, .col-2{
    flex: unset !important;
    max-width: unset !important;
    padding: unset !important
  }
  .row{
    margin: unset !important;
  }
  .patientDetailsClass{
    display: none !important;
  }
  .viewPatientMainClass{
    position: unset !important;
    width: 100% !important;
    height: unset !important;
    margin-top: unset !important;
  }
  .mobileResponsivePatientDetails{
  display: block !important;
}
.prescriptionHistoryClass{
  flex-direction: column !important;
}
.prescriptionCard{
  width: auto !important;
}
.docViewAppointmentMedicalDetails{
        max-width: unset;
        flex: unset;
        text-align: left;
        border-style: Unset !important;
        display: flex !important;
}
.prescriptionTableClass{
      display: none;
}
.PrescriptionTableMobileLayout{
      display: block;
}
.persistingSymptomsClass{
      max-width: unset;
      flex: unset;
      padding:unset;
    }
.presentingSysmptoms{
  padding-left:12px ;
}
}
</style>